import Routes from "routes/index";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { StyleProvider } from "@ant-design/cssinjs";

function App() {
    return (
        <div className="app">
            <StyleProvider hashPriority="high">
                <ToastContainer style={{ zIndex: 9999 }} pauseOnFocusLoss={false} limit={1} theme="colored" />
                <Routes />
            </StyleProvider>
        </div>
    );
}

export default App;
